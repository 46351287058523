export function MapDirectionsButton(props: React.HTMLAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            {...props}>
            <rect width={56} height={56} fill="#2A134E" rx={8} />
            <path
                fill="#fff"
                d="M31.075 39.252a1.838 1.838 0 0 1-2.054 1.107c-.86-.172-1.532-.923-1.532-1.801V28.459H17.39c-.826 0-1.58-.62-1.75-1.48a1.837 1.837 0 0 1 1.106-2.054l20.199-8.264a1.837 1.837 0 0 1 2.393 2.395l-8.263 20.196Z"
            />
        </svg>
    )
}
