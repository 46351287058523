import { SVGProps } from "react"

function VideoPlayButton(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 120"
            width={120}
            height={120}
            fill="none"
            {...props}>
            <g clipPath="url(#a)">
                <path
                    fill="#fff"
                    fillOpacity={0.2}
                    stroke="#fff"
                    strokeWidth={6}
                    d="M60 117c31.48 0 57-25.52 57-57S91.48 3 60 3 3 28.52 3 60s25.52 57 57 57Z"
                />
                <path
                    fill="#FF1ABA"
                    d="M83.66 64.447c3.513-1.875 3.513-6.91 0-8.785L46.37 35.772c-3.316-1.77-7.32.634-7.32 4.392v39.78c0 3.759 4.004 6.162 7.32 4.393l37.289-19.89Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="m86.543 50.258-37.29-19.89c-7.396-3.947-16.33 1.414-16.33 9.797v39.781c0 8.384 8.934 13.744 16.33 9.798l37.29-19.89c7.837-4.181 7.837-15.415 0-19.596Zm-2.884 5.405c3.513 1.875 3.513 6.91 0 8.785L46.37 84.338c-3.316 1.77-7.32-.634-7.32-4.392v-39.78c0-3.759 4.005-6.162 7.32-4.393l37.289 19.89Z"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h120v120H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default VideoPlayButton
