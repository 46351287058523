import React from "react"
import { Modal } from "../../modal/Modal"

export const ErrorModal = (errorText: string[]) => {
    return Modal((close) => {
        return (
            <div
                className="container"
                style={{
                    backgroundColor: "white",
                    padding: 20,
                    paddingBottom: 30,
                    borderRadius: 8,
                    maxHeight: 400,
                    width: 500,
                }}>
                <div className="row">
                    <div className="col-10">
                        {errorText.map((t, i) => {
                            return (
                                <div key={i}>
                                    {t}
                                    <br />
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-2">{CloseButton(close)}</div>
                </div>
            </div>
        )
    })
}

const CloseButton = (close: (result: unknown) => void) => {
    return (
        <div
            className="row"
            onClick={() => {
                close(undefined)
                window.location.reload()
            }}
            style={{ cursor: "pointer" }}>
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="1"
                    y="15.1406"
                    width="20"
                    height="3"
                    rx="1.5"
                    transform="rotate(-45 1 15.1406)"
                    fill="#2A134E"
                />
                <rect
                    width="20"
                    height="3"
                    rx="1.5"
                    transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 17.2617 15.1406)"
                    fill="#2A134E"
                />
            </svg>
        </div>
    )
}
