import { ReactNode, useEffect, useRef, useState } from "react"
import { css } from "@emotion/react"

export function Collapsible({
    collapsed = false,
    children,
}: {
    collapsed?: boolean
    children: ReactNode
}) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        if (!collapsed) {
            if (containerRef.current?.clientHeight)
                setHeight(containerRef.current?.clientHeight || 0)
        }
    }, [collapsed])

    return (
        <div
            css={css`
                overflow: hidden;
                height: ${collapsed ? 0 : `${height}px`};
                transition: height 0.25s;
            `}>
            <div ref={containerRef}>{children}</div>
        </div>
    )
}
