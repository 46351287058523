import React from "react"

export function MenuBurger() {
    return (
        <svg
            width="35"
            height="40"
            viewBox="0 0 35 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_322_1664)">
                <path
                    d="M0 7.5C0 6.11953 1.11258 5 2.48447 5H32.2981C33.6724 5 34.7826 6.11953 34.7826 7.5C34.7826 8.88281 33.6724 10 32.2981 10H2.48447C1.11258 10 0 8.88281 0 7.5ZM0 20C0 18.6172 1.11258 17.5 2.48447 17.5H32.2981C33.6724 17.5 34.7826 18.6172 34.7826 20C34.7826 21.3828 33.6724 22.5 32.2981 22.5H2.48447C1.11258 22.5 0 21.3828 0 20ZM32.2981 35H2.48447C1.11258 35 0 33.8828 0 32.5C0 31.1172 1.11258 30 2.48447 30H32.2981C33.6724 30 34.7826 31.1172 34.7826 32.5C34.7826 33.8828 33.6724 35 32.2981 35Z"
                    fill="#F2F1F4"
                />
            </g>
            <defs>
                <clipPath id="clip0_322_1664">
                    <rect width="34.7826" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
