import React, { useState } from "react"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { colors } from "../../colors"

export function Field<T>({
    section,
    prop,
    children,
}: {
    section: T
    prop: keyof T
    children: React.ReactNode
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                marginTop: 16,
            }}>
            <div className="bold">
                <EditableText
                    obj={section}
                    prop={prop}
                    isLocalized={true}
                    defaultText={prop.toString()}
                />
            </div>
            {children}
        </div>
    )
}

export function TextField<T, TValue extends String>({
    section,
    prop,
    value,
    valueChanged,
    validator,
}: {
    section: T
    prop: keyof T
    value: TValue | undefined
    valueChanged: (text: TValue) => void
    validator?: (text: string) => TValue
}) {
    const [localValue, setLocalValue] = useState(value?.valueOf() ?? "")
    const [validationError, setValidationError] = useState<string | undefined>()
    return (
        <Field section={section} prop={prop}>
            <input
                style={{
                    marginTop: 8,
                    marginBottom: 8,
                    border: "none",
                    borderRadius: 8,
                    height: 48,
                    padding: 16,
                    width: "100%",
                }}
                type="text"
                id="name"
                value={localValue}
                onChange={(e) => {
                    const v = e.target.value
                    setLocalValue(v)
                    try {
                        if (validator) {
                            const vv = validator(v)
                            setValidationError(undefined)
                            valueChanged(vv)
                        } else {
                            valueChanged(v as any as TValue)
                        }
                    } catch (error: any) {
                        setValidationError(error.message)
                    }
                }}
            />
            <div style={{ fontSize: 14, color: colors.lollipop }}>{validationError}</div>
        </Field>
    )
}
