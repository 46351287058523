import React, { useState } from "react"
import { useHover } from "../hooks/useHover"
import { useEditableContext } from "./EditableContext"

/**
 * Wraps a div in a container where the user can move the div around by dragging
 * @param props
 */
export function EditablePosition<T>(props: {
    obj: T
    xProp: keyof T
    yProp: keyof T
    children: React.ReactNode
}) {
    const { hover, hoverProps } = useHover()
    const { editing } = useEditableContext()
    const [dragStart, setDragStart] = useState<{ x: number; y: number } | undefined>()
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })

    ;(props.obj as any)[props.xProp] ??= 0
    ;(props.obj as any)[props.yProp] ??= 0
    const x = (props.obj as any)[props.xProp] + dragOffset.x
    const y = (props.obj as any)[props.yProp] + dragOffset.y

    return (
        <div
            {...hoverProps}
            onMouseDown={
                editing
                    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          setDragStart({ x: e.clientX, y: e.clientY })
                          e.preventDefault()
                          e.stopPropagation()
                      }
                    : undefined
            }
            onMouseMove={
                editing
                    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          if (dragStart) {
                              setDragOffset({
                                  x: e.clientX - dragStart.x,
                                  y: e.clientY - dragStart.y,
                              })
                              e.preventDefault()
                              e.stopPropagation()
                          }
                      }
                    : undefined
            }
            onMouseUp={
                editing
                    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          if (dragStart) {
                              ;(props.obj as any)[props.xProp] += e.clientX - dragStart.x
                              ;(props.obj as any)[props.yProp] += e.clientY - dragStart.y
                              setDragStart(undefined)
                              setDragOffset({ x: 0, y: 0 })
                              e.preventDefault()
                              e.stopPropagation()
                          }
                      }
                    : undefined
            }
            style={{
                pointerEvents: editing ? "auto" : "none",
                position: "absolute",
                cursor: editing ? "move" : undefined,
                left: x,
                top: y,
                border: editing && hover ? "1px dashed blue" : "none",
            }}>
            {props.children}
        </div>
    )
}
