import { Color, colors } from "../colors"

export function Chevron({
    direction,
    color = "purple",
    ...props
}: React.HTMLAttributes<SVGElement> & {
    direction: "up" | "down" | "left" | "right"
    color?: Color
}) {
    const rotations = {
        up: 180,
        down: 0,
        left: 90,
        right: -90,
    }

    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            style={{
                transform: `rotate(${rotations[direction]}deg)`,
                transition: "transform 0.2s",
            }}
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                fill={colors[color]}
                d="M9.609 14.8c.343 0 .687-.132.948-.396l8.05-8.1a1.355 1.355 0 0 0 0-1.908 1.335 1.335 0 0 0-1.897 0l-7.101 7.147-7.102-7.146a1.335 1.335 0 0 0-1.897 0 1.355 1.355 0 0 0 0 1.909l8.05 8.1c.261.263.605.394.949.394Z"
            />
        </svg>
    )
}

export function ChevronLeft() {
    return (
        <svg
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_623_251)">
                <path
                    d="M4.5 10.2858C4.5 10.6618 4.64648 11.0379 4.93945 11.3245L13.9395 20.1408C14.5254 20.7148 15.4746 20.7148 16.0605 20.1408C16.6465 19.5668 16.6465 18.637 16.0605 18.063L8.11875 10.2858L16.0594 2.50723C16.6453 1.93325 16.6453 1.00341 16.0594 0.429428C15.4734 -0.14455 14.5242 -0.14455 13.9383 0.429428L4.93828 9.24575C4.64531 9.53274 4.5 9.90928 4.5 10.2858Z"
                    fill="#721A9F"
                />
            </g>
            <defs>
                <clipPath id="clip0_623_251">
                    <rect
                        width="20.5714"
                        height="24"
                        fill="white"
                        transform="matrix(0 -1 -1 0 24 20.5713)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export function ChevronRight({ fill }: { fill?: string }) {
    return (
        <svg
            width="13"
            height="21"
            viewBox="0 0 13 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 10.2858C12.5 10.6618 12.3535 11.0379 12.0605 11.3245L3.06055 20.1408C2.47461 20.7148 1.52539 20.7148 0.939453 20.1408C0.353516 19.5668 0.353516 18.637 0.939453 18.063L8.88125 10.2858L0.940625 2.50723C0.354687 1.93325 0.354687 1.00341 0.940625 0.429428C1.52656 -0.14455 2.47578 -0.14455 3.06172 0.429428L12.0617 9.24575C12.3547 9.53274 12.5 9.90928 12.5 10.2858Z"
                fill={fill ?? "#721A9F"}
            />
        </svg>
    )
}
