import React, { useRef, useState } from "react"
import { css } from "@emotion/react"
import { GetPageVenue1Dto, SingleTicketPrice, useVenueSingleTicketPriceCalendar } from "../client"
import { NonNegativeInteger } from "../../../../reactor/Types/Primitives/NonNegativeNumber"
import moment from "moment"
import Prando from "prando"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { colors, gradients, shadows } from "../colors"
import { SplitView } from "../SplitView"
import { EditableText } from "../../../../packages/editing/EditableText"

const prices: Localized<string> = {
    en: "Prices",
    no: "Priser",
    sv: "Priser",
    da: "Priser",
}

const expectedVisits: Localized<string> = {
    en: "Expected visits",
    no: "Forventet besøk",
    sv: "Förväntade besök",
    da: "Forventede besøg",
}

const outsideTimerange: Localized<string> = {
    en: "No data available for this date",
    no: "Data er ikke tilgjengelig for denne datoen",
    sv: "Data är inte tillgänglig för detta datum",
    da: "Data er ikke tilgængelige for denne dato",
}

const time: Localized<string> = {
    en: "",
    no: "kl.",
    sv: "kl.",
    da: "kl.",
}

const closed: Localized<string> = {
    en: "We are closed on this date",
    no: "Vi holder stengt denne dagen",
    sv: "Vi har stängt denna dag",
    da: "Vi holder lukket denne dag"
}

export function VenueSingleTicketPriceCalendar({ venue }: { venue: GetPageVenue1Dto }) {
    const localize = useLocalize()
    const [date, setDate] = useState(new Date())
    const cal = useVenueSingleTicketPriceCalendar(
        venue.id,
        "month",
        NonNegativeInteger(8),
        moment().subtract(2, "months").format("YYYY-MM-DD")
    )

    const dateInputRef = useRef<HTMLInputElement>(null)
    const price = cal.data?.singleTicketPriceCalendar.calendar
        .flat()
        .find((x) => x.date === moment(date).format("YYYY-MM-DD"))

    const ticketTypes = Array.from(
        new Set(price?.timesOfDay.flatMap((x) => x.tickets).map((x) => localize(x.name)))
    )

    const fluctuatingTicketTypes = ticketTypes.filter((x) =>
        price?.timesOfDay.some(
            (td) =>
                td.tickets.find((t) => localize(t.name) === x)?.price !==
                price?.timesOfDay[0]?.tickets.find((t) => localize(t.name) === x)?.price
        )
    )
    const rnd = new Prando(date.toISOString())

    // Find which fluctuating ticket type to present as calendar by finding the
    // ticket type that has the highest price (for the first time slot) and is
    // also one of the ticket types in the fluctuating ticket types array
    const fluctuatingTicketTypeNameToShow = price?.timesOfDay[0]?.tickets.reduce(
        (acc: SingleTicketPrice | null, curr: SingleTicketPrice) => {
            acc =
                fluctuatingTicketTypes.includes(localize(curr?.name)) &&
                    curr?.price > (acc?.price ?? 0)
                    ? curr
                    : acc

            return acc
        },
        null
    )?.name

    return (
        <SplitView
            reverseOnMobile
            columns={[
                () => (
                    <div>
                        <div>
                            {price?.dayStatus === "Closed" ? (
                                <div style={{ marginTop: 16 }}>{localize(closed)}</div>
                            ) :
                                fluctuatingTicketTypes.length === 0 ? (
                                    <div style={{ marginTop: 16 }}>{localize(outsideTimerange)}</div>
                                ) : null}

                            {fluctuatingTicketTypes.map((ticket) => {
                                if (
                                    !fluctuatingTicketTypeNameToShow ||
                                    ticket !== localize(fluctuatingTicketTypeNameToShow)
                                ) {
                                    return null
                                }

                                const lowestPrice = price?.timesOfDay
                                    .map((tod) =>
                                        tod.tickets
                                            .find((t) => localize(t.name) === ticket)
                                            ?.price.valueOf()
                                    )
                                    .reduce(
                                        (a, b) => ((a ?? 0) < (b ?? 0) ? a : b),
                                        Number.MAX_VALUE
                                    )

                                const times = price?.timesOfDay.flatMap((tod) => {
                                    const start = parseInt(tod.start.split(":")[0])
                                    const end = parseInt(tod.end.split(":")[0])
                                    const price =
                                        tod.tickets
                                            .find((t) => localize(t.name) === ticket)
                                            ?.price.valueOf() ?? 0
                                    const hr = []

                                    for (let i = start; i < end; i++) {
                                        hr.push({ i, busy: price })
                                    }

                                    return hr
                                })

                                let busy = times?.map((t) => t.busy)

                                if (busy)
                                    for (let iter = 0; iter < 5; iter++) {
                                        const newBusy: number[] = busy.slice()
                                        for (let i = 0; i < busy.length; i++) {
                                            newBusy[i] =
                                                (busy[Math.max(0, i - 1)] +
                                                    busy[i] +
                                                    busy[Math.min(busy.length - 1, i + 1)]) /
                                                3 +
                                                rnd.next(-0.5, 0.5)
                                        }
                                        busy = newBusy
                                    }

                                const min =
                                    (times?.map((x) => x.busy).reduce((a, b) => (a < b ? a : b)) ??
                                        0) - 5
                                const max =
                                    times?.map((x) => x.busy).reduce((a, b) => (a > b ? a : b)) ?? 1

                                return (
                                    <div
                                        key={ticket.valueOf()}
                                        css={css`
                                            display: flex;
                                            flex-direction: column;
                                        `}>
                                        <div
                                            css={css`
                                                display: flex;
                                                align-items: center;
                                            `}>
                                            <div
                                                css={css`
                                                    display: inline-block;
                                                    background-color: ${colors.purple};
                                                    width: 8px;
                                                    height: 8px;
                                                    border-radius: 8px;
                                                    margin-right: 8px;
                                                `}></div>
                                            {localize(expectedVisits)}
                                        </div>
                                        <div css={css({ padding: "0 24px" })}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    marginTop: 40,
                                                    marginBottom: 24,
                                                }}>
                                                {times?.map(
                                                    (t, i) =>
                                                        busy && (
                                                            <div
                                                                key={`${t.busy}${t.i}`}
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                }}>
                                                                <div
                                                                    style={{
                                                                        marginTop: 16,
                                                                        height: 200,
                                                                        width: 8,
                                                                        borderRadius: 8,
                                                                        backgroundColor: "#E0DDE5",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        justifyContent: "flex-end",
                                                                        marginBottom: 12,
                                                                    }}>
                                                                    <div
                                                                        style={{
                                                                            height:
                                                                                ((busy[i] - min) /
                                                                                    (max - min)) *
                                                                                200,
                                                                            width: 8,
                                                                            borderRadius: 8,
                                                                            backgroundColor:
                                                                                "#721A9F",
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>{t.i}</div>
                                                            </div>
                                                        )
                                                )}
                                            </div>

                                            <div
                                                css={css`
                                                    width: 100%;
                                                    border-radius: 8px;
                                                    border: 2px solid ${colors.accentDimples};
                                                    color: #fff;
                                                    position: relative;
                                                    height: 42px;
                                                    margin-bottom: 14px;
                                                    cursor: pointer;

                                                    input[type="date"]:focus-visible {
                                                        outline: 0;
                                                    }
                                                    z-index: 2;
                                                    input[type="date"] {
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        bottom: 0;
                                                        left: 0;
                                                        color: transparent;
                                                        background: transparent;
                                                        border: 0px;
                                                        cursor: pointer;
                                                    }

                                                    input[type="date"]::-webkit-datetime-edit {
                                                        opacity: 0;
                                                        width: 100%;
                                                    }

                                                    input[type="date"]::-webkit-datetime-edit-fields-wrapper {
                                                        width: 100%;
                                                    }

                                                    input[type="date"]::-webkit-calendar-picker-indicator {
                                                        background: transparent;
                                                        color: transparent;
                                                        cursor: pointer;
                                                        height: auto;
                                                        position: absolute;
                                                        top: 0;
                                                        right: 0;
                                                        bottom: 0;
                                                        left: 0;
                                                        width: auto;
                                                    }
                                                `}>
                                                <input
                                                    ref={dateInputRef}
                                                    type="date"
                                                    value={moment(date).format("YYYY-MM-DD")}
                                                    onChange={(v) =>
                                                        setDate(new Date(v.target.value))
                                                    }
                                                    css={css({ zIndex: 5 })}
                                                    onClick={() => {
                                                        if (
                                                            /mobile/gi.test(navigator.userAgent) ===
                                                            false &&
                                                            dateInputRef.current
                                                        ) {
                                                            dateInputRef.current.style.zIndex = "-1"
                                                        }
                                                    }}
                                                />
                                                <div
                                                    css={css({
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        backgroundColor: colors.pinkFlirt,
                                                        zIndex: 2,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    })}
                                                    onClick={() => {
                                                        if (dateInputRef.current) {
                                                            if (
                                                                /chrome|firefox/gi.test(
                                                                    navigator.userAgent
                                                                ) &&
                                                                typeof dateInputRef.current
                                                                    .showPicker === "function"
                                                            ) {
                                                                dateInputRef.current.showPicker()
                                                            } else {
                                                                dateInputRef.current.click()
                                                            }
                                                        }
                                                    }}>
                                                    {moment(date).format("dddd DD.MM")}{" "}
                                                    <i
                                                        className="fa fa-calendar"
                                                        css={css({ marginLeft: 10 })}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                css={css`
                                                    display: grid;
                                                    grid-template-columns: 1fr;
                                                    gap: 14px;

                                                    @media (min-width: 1024px) {
                                                        grid-auto-columns: minmax(0, 1fr);
                                                        grid-auto-flow: column;
                                                    }
                                                `}>
                                                {price?.timesOfDay.map((tod) => {
                                                    const tt = tod.tickets.find(
                                                        (t) => localize(t.name) === ticket
                                                    )

                                                    return (
                                                        <div
                                                            key={`${tod.start}${tod.end}`}
                                                            css={css`
                                                                border-radius: 8px;
                                                                color: ${colors.white};
                                                                flex: 1;
                                                                background: ${gradients.purpleToDarkPurple};
                                                                display: flex;
                                                                justify-content: center;
                                                                position: relative;
                                                            `}
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                padding: 12,
                                                                paddingLeft: 16,
                                                                paddingRight: 16,
                                                                borderRadius: 8,
                                                                fontSize: 16,
                                                                alignItems: "center",
                                                                lineHeight: "normal",
                                                            }}>
                                                            <div css={css({ marginBottom: 4 })}>
                                                                {localize(time)}{" "}
                                                                {prettyTime(tod.start)} -{" "}
                                                                {prettyTime(tod.end)}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontFamily: "GT-Haptik-Black",
                                                                    fontSize: 22,
                                                                    lineHeight: "normal",
                                                                }}>
                                                                {tt?.price.valueOf()} kr
                                                            </div>
                                                            {tt?.price === lowestPrice && (
                                                                <Sparkling
                                                                    css={css`
                                                                        position: absolute;
                                                                        bottom: -13px;
                                                                        left: -21px;
                                                                        ${shadows.icon}
                                                                    `}
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ),
                () => (
                    <div>
                        <h2 css={css({ marginTop: 0 })}>{localize(prices)}</h2>
                        <div
                            css={css`
                                margin-bottom: 32px;
                            `}>
                            {ticketTypes &&
                                ticketTypes.map((ticket) => (
                                    <div key={ticket}>
                                        {ticket}:{" "}
                                        <span className="black">
                                            {fluctuatingTicketTypes.includes(ticket) ? "Fra " : ""}
                                            {price?.timesOfDay[0]?.tickets
                                                .find((t) => localize(t.name) === ticket)
                                                ?.price.valueOf()}{" "}
                                            kr
                                        </span>
                                    </div>
                                ))}
                        </div>
                        <EditableText
                            obj={venue}
                            prop="priceInfo"
                            isMarkdown={true}
                            isLocalized={true}
                            defaultText={`# Prices for ${localize(
                                venue.name
                            )}\nPlease provide price info about ${localize(venue.name)} here.`}
                        />
                    </div>
                ),
            ]}
        />
    )
}

function prettyTime(t: string) {
    return t.replace(":00", "")
}

function Sparkling(props: React.HTMLAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={60}
            height={60}
            fill="none"
            viewBox="0 0 60 60"
            {...props}>
            <path
                fill="#fff"
                d="m36.653 47.097 4.042 2.21 2.208 4.043a1.253 1.253 0 0 0 2.194 0l2.21-4.043 4.04-2.21a1.25 1.25 0 0 0 .003-2.194l-4.043-2.21-2.21-4.043a1.253 1.253 0 0 0-2.194 0l-2.21 4.043-4.043 2.21a1.253 1.253 0 0 0 0 2.194"
            />
            <path
                fill="#FFB100"
                d="M41.615 48.385 44 52.75l2.385-4.365L50.75 46l-4.365-2.385L44 39.25l-2.385 4.365L37.25 46l4.365 2.385Z"
            />
            <path
                fill="#fff"
                d="m11.979 21.646 6.064 3.315 3.31 6.064a1.88 1.88 0 0 0 3.293 0l3.315-6.064 6.06-3.315a1.876 1.876 0 0 0 .004-3.292l-6.064-3.315-3.315-6.064a1.88 1.88 0 0 0-3.292 0l-3.315 6.064-6.064 3.315a1.88 1.88 0 0 0 0 3.292"
            />
            <path
                fill="#FFB100"
                d="M19.422 23.578 23 30.125l3.578-6.547L33.124 20l-6.547-3.578L23 9.875l-3.578 6.547L12.875 20l6.547 3.578Z"
            />
        </svg>
    )
}
