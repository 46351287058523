import { Link } from "react-router-dom"
import { css } from "@emotion/react"
import { Markdown } from "../../../../reactor"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Localized } from "../../../../packages/localization/Localized"
import { FileToUrl, type Image, ImageToUrl, type Video } from "../../../../reactor/Types/File"
import { Section } from "../../../../packages/editing/Section"
import { usePageContext } from "../Page"
import { CallToAction } from "./TextAndCallToAction"
import { useCallback, useEffect, useRef, useState } from "react"
import VideoPlayButton from "../assets/VideoPlayButton"
import { mediaQuery, resetStyles, responsiveCss } from "../css"
import { SectionWrapper } from "../views/SectionWrappper"

interface TextAndMedia extends SectionWrapper {
    /**
     * @default '{"en": "Welcome!", "no": "Velkommen!"}'
     */
    title: Localized<string>

    /**
     * @default '{"en": "A paragraph or more about this section.", "no": "Et eller flere avsnitt om denne seksjonen."}'
     */
    text: Localized<Markdown>

    /**
     * An image to display in this section. Will be used as preview image for video if a video is uploaded.
     */
    image?: Image

    /**
     * A video to display in this section. To get a preview image, upload an image as well.
     */
    video?: Video

    /**
     * How many percent of the image should be cropped on mobile. This will only be applied to left or right positioned images.
     * @default 0
     */
    imageCropPercentageOnMobile?: number

    /**
     * @default "left"
     */
    mediaPosition: "left" | "right" | "fullwidth"

    /**
     * @default "aboveText"
     */
    mediaPositionOnMobile: "aboveText" | "belowText" | "hidden"

    callToAction?: { text: Localized<string>; link: string }
}

Section(TextAndMedia)
function TextAndMedia(section: TextAndMedia) {
    const { venue, locale } = usePageContext()

    return (
        <SectionWrapper section={section}>
            <div
                css={css(css`
                    width: 100%;
                    display: grid;

                    /**
                     * Grid template areas:
                     * img = image
                     * hdg = heading
                     * txt = text
                     */
                    grid-template-areas: ${section.mediaPositionOnMobile === "aboveText"
                        ? `"img" "hdg" "txt"`
                        : section.mediaPositionOnMobile === "belowText"
                          ? `"hdg" "txt" "img"`
                          : section.mediaPositionOnMobile === "hidden"
                            ? `"hdg" "txt"`
                            : ""};

                    grid-template-columns: 1fr;
                    gap: 32px 50px;

                    ${mediaQuery("min", "sm")} {
                        grid-template-areas: ${section.mediaPosition === "left"
                            ? `"img hdg" "img txt"`
                            : section.mediaPosition === "right"
                              ? `"hdg img" "txt img"`
                              : section.mediaPosition === "fullwidth"
                                ? `" img img" " hdg txt"`
                                : ""};

                        grid-template-columns: 1fr 1fr;
                        gap: 50px;
                        grid-template-rows: auto 1fr;
                    }
                `)}>
                {(section.video || section.image) && (
                    <div
                        css={css(
                            {
                                overflow: "hidden",
                                gridArea: "img",
                                display: "flex",
                                alignItems: "flex-start",
                            },
                            ["left", "right"].includes(section.mediaPosition) && {
                                justifyContent: section.mediaPosition,
                            },
                            (section.video ||
                                section.mediaPosition === "fullwidth" ||
                                !!section.imageCropPercentageOnMobile) &&
                                responsiveCss("max", "xs", {
                                    width: "100vw",
                                    overflow: "hidden",
                                    marginLeft: "calc(50% - 50vw)",
                                })
                        )}>
                        {section.video ? (
                            <div
                                css={css(
                                    !section.imageCropPercentageOnMobile &&
                                        section.mediaPosition === "fullwidth" &&
                                        responsiveCss("max", "xs", {
                                            height: "100vw",
                                        })
                                )}>
                                <ResponsiveVideo
                                    video={section.video}
                                    previewImage={section.image}
                                    sizeBy={
                                        section.image && section.mediaPosition === "fullwidth"
                                            ? "image"
                                            : "video"
                                    }
                                />
                            </div>
                        ) : section.image ? (
                            <img
                                src={ImageToUrl(section.image, {
                                    format: "png",
                                    width: section.mediaPosition === "fullwidth" ? 1200 : 500,
                                })}
                                css={css(
                                    { width: "100%" },
                                    !!section.imageCropPercentageOnMobile &&
                                        ["left", "right"].includes(section.mediaPosition) &&
                                        responsiveCss("max", "xs", {
                                            width: `calc(100vw + ${section.imageCropPercentageOnMobile}%)`,
                                            [section.mediaPosition === "left"
                                                ? "marginLeft"
                                                : "marginRight"]: `calc(
                                            50% - 50vw - ${section.imageCropPercentageOnMobile / 2}%
                                        )`,
                                        }),
                                    section.mediaPosition === "fullwidth" &&
                                        responsiveCss("max", "xs", {
                                            height: "100vw",
                                            objectFit: "cover",
                                        }),
                                    responsiveCss("min", "sm", {
                                        width:
                                            section.mediaPosition === "fullwidth" ? "100%" : "85%",
                                    })
                                )}
                            />
                        ) : null}
                    </div>
                )}
                <div
                    css={css({
                        gridArea: "hdg",
                    })}>
                    <h1
                        css={css({
                            margin: 0,
                        })}>
                        <EditableText obj={section} prop="title" defaultText="Say something here" />
                    </h1>
                </div>
                <div
                    css={css(
                        {
                            gridArea: "txt",
                            display: "flex",
                            flexDirection: "column",
                        },
                        responsiveCss("max", "xs", { alignItems: "flex-start" })
                    )}>
                    <EditableText
                        obj={section}
                        prop="text"
                        defaultText="A paragraph or more about this section."
                        isMarkdown
                    />
                    {section.callToAction && section.callToAction.link && (
                        <Link
                            css={css(
                                {
                                    display: "inline-block",
                                    marginTop: "48px",
                                },
                                responsiveCss("min", "sm", { marginTop: "60px" })
                            )}
                            to={
                                section.callToAction.link.startsWith("https://")
                                    ? section.callToAction.link
                                    : `/${locale}/${venue ? venue.slug + "/" : ""}${
                                          section.callToAction.link
                                      }`
                            }>
                            {section.callToAction && (
                                <CallToAction
                                    align="flex-start"
                                    section={{ callToAction: section.callToAction.text }}
                                    prop="callToAction"
                                />
                            )}
                        </Link>
                    )}
                </div>
            </div>
        </SectionWrapper>
    )
}

function ResponsiveVideo({
    video,
    previewImage,
    sizeBy,
}: {
    video: Video
    previewImage?: Image
    sizeBy: "video" | "image"
}) {
    const videoRef = useRef<HTMLVideoElement>(null)

    const [isPreview, setIsPreview] = useState(!!previewImage)
    const [playsInline, setPlaysInline] = useState(true)

    useEffect(() => {
        if (typeof window !== "undefined" && window.matchMedia("(max-width: 479px)").matches) {
            setPlaysInline(false)
        }
    }, [])

    const handlePlayButtonClick = useCallback(() => {
        setIsPreview(false)
        void videoRef.current?.play()
    }, [videoRef, setIsPreview])

    return (
        <div css={css({ lineHeight: 0, height: "100%" })}>
            <div
                css={css({
                    position: "relative",
                    backgroundColor: "#000",
                    minHeight: "100%",
                    height: "100%",
                })}>
                {!!isPreview && (
                    <button
                        css={css([
                            resetStyles("button"),
                            {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 3,
                            },
                        ])}
                        onClick={handlePlayButtonClick}>
                        <VideoPlayButton />
                    </button>
                )}
                <video
                    ref={videoRef}
                    controls={true}
                    playsInline={playsInline}
                    css={css(
                        sizeBy === "video"
                            ? {
                                  width: "100%",
                                  height: "100%",
                              }
                            : {
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  height: "100%",
                                  maxWidth: "100%",
                                  transform: "translate(-50%, -50%)",
                                  opacity: isPreview ? 0 : 1,
                              }
                    )}
                    src={`${FileToUrl(video as any)}#t=0.1$`}
                />
                {!!previewImage && (
                    <img
                        src={ImageToUrl(previewImage, {
                            // If sizeBy image, this is a fullwidth section and should be larger
                            // than if in a column.
                            width: sizeBy === "image" ? 1200 : 500,
                        })}
                        css={css(
                            sizeBy === "video" && {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "100%",
                                height: "100%",
                                transform: "translate(-50%, -50%)",
                                justifyContent: "center",
                                zIndex: 1,
                                overflow: "hidden",
                            },
                            {
                                width: "100%",
                                objectFit: "cover",
                                height: "auto",
                                minHeight: "100%",
                                opacity: isPreview ? 1 : 0,
                            }
                        )}
                    />
                )}
            </div>
        </div>
    )
}
