import { ReactNode } from "react"
import { css, type SerializedStyles } from "@emotion/react"
import { colors, getTextColorForBackgroundColor } from "../colors"
import { responsiveCss } from "../css"

type SpacingOptions = "normal" | "reduced" | "none"

export type SectionWrapper = {
    /**
     * Override default spacing inside or outside of section.
     */
    spacing?: {
        inside?: {
            /**
             * @default "normal"
             */
            top: SpacingOptions

            /**
             * @default "normal"
             */
            bottom: SpacingOptions
        }
        outside?: {
            /**
             * @default "normal"
             */

            top: SpacingOptions

            /**
             * @default "normal"
             */
            bottom: SpacingOptions
        }
    }

    /**
     * Background color of the section. Will cause text to be white, so be sure to use a color with
     */
    backgroundColor?: keyof typeof colors
}

function getMargins(spacing?: SpacingOptions) {
    switch (spacing) {
        case "reduced":
            return { xs: 8, sm: 12 }
        case "none":
            return {
                xs: 0,
                sm: 0,
            }
        case "normal":
        default:
            return { xs: 30, sm: 40 }
    }
}

function getPadding(spacing?: SpacingOptions) {
    switch (spacing) {
        case "reduced":
            return { xs: 16, sm: 24 }
        case "none":
            return {
                xs: 0,
                sm: 0,
            }
        case "normal":
        default:
            return { xs: 60, sm: 80 }
    }
}

function outerCss(section: SectionWrapper): SerializedStyles {
    return css(
        !!section.backgroundColor && [
            {
                backgroundColor: colors[section.backgroundColor],
                color: getTextColorForBackgroundColor(section.backgroundColor),
                marginTop: getMargins(section?.spacing?.outside?.top).xs,
                marginBottom: getMargins(section?.spacing?.outside?.bottom).xs,
                textarea: {
                    color: getTextColorForBackgroundColor(section.backgroundColor),
                    width: "100%",
                },
            },
            responsiveCss("min", "sm", {
                marginTop: getMargins(section?.spacing?.outside?.top).sm,
                marginBottom: getMargins(section?.spacing?.outside?.bottom).sm,
            }),
        ]
    )
}

function innerCss(section: SectionWrapper): SerializedStyles {
    const topPadding = getPadding(section?.spacing?.inside?.top)
    const bottomPadding = getPadding(section?.spacing?.inside?.bottom)

    return css(
        {
            paddingTop: section.backgroundColor ? topPadding.xs * 1.5 : topPadding.xs,
            paddingBottom: section.backgroundColor ? bottomPadding.xs * 1.5 : bottomPadding.xs,
            paddingLeft: 18,
            paddingRight: 18,
        },
        responsiveCss("min", "sm", {
            paddingTop: section.backgroundColor ? topPadding.sm * 1.5 : topPadding.sm,
            paddingBottom: section.backgroundColor ? bottomPadding.sm * 1.5 : bottomPadding.sm,
            paddingLeft: 40,
            paddingRight: 40,
        })
    )
}

export function SectionWrapper({
    section,
    children,
}: {
    section: SectionWrapper
    children: ReactNode
}) {
    return (
        <div
            css={css(
                { display: "flex", justifyContent: "center", overflow: "hidden", width: "100%" },
                outerCss(section)
            )}>
            <div
                css={css(
                    { width: "100%", maxWidth: 1280, display: "flex", justifyContent: "center" },
                    innerCss(section)
                )}>
                {children}
            </div>
        </div>
    )
}
