import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { usePageContext } from "../Page"
import { SplitView } from "../SplitView"
import { EditableText } from "../../../../packages/editing/EditableText"

/**
 * Each venue has an array of more info markdown texts, that can be displayed on the venue info
 * page by including this section. Will be stacked in two columns side by side.
 */
Section(VenueExtraInfo)
function VenueExtraInfo(section: {}) {
    const { venue } = usePageContext()

    // Assume this won't be used outside of a venue context
    if (!venue) return <></>

    return !!venue.webExtraInfo?.length ? (
        <div
            css={css`
                h1:first-of-type,
                h2:first-of-type,
                h3:first-of-type,
                h4:first-of-type,
                h5:first-of-type {
                    margin-top: 0;
                }
            `}>
            {(function groupArrayInPairs<T>(array: T[]): T[][] {
                return array.reduce((acc: T[][], _, index) => {
                    if (index % 2 === 0) {
                        acc.push(array.slice(index, index + 2))
                    }
                    return acc
                }, [])
            })(venue.webExtraInfo).map((weiGroups, index) => (
                <SplitView
                    key={index}
                    columns={weiGroups.map((wei) => () => (
                        <EditableText obj={wei} prop="text" isMarkdown />
                    ))}
                />
            ))}
        </div>
    ) : (
        <></>
    )
}
