import React from "react"
import "../../../packages/ssr/SSR2"
import { App } from "./App"
import { BrowserRouter } from "react-router-dom"
import { Login } from "../../../packages/ssr/Login"
import { defaults } from "./client"
import { defaults as studioDefaults } from "../../../studio/client"
import { InitWebApp } from "../../../packages/ssr/InitWebApp"
import { SidebarTheme } from "../../../studio/Views/SidebarTheme"

SidebarTheme.light = false
SidebarTheme.background = "linear-gradient(334deg, #27144B 12.18%, #692199 79.93%)"

InitWebApp(() => (
    <Login
        always={false}
        returnTo={"/"}
        tokenReady={(token) => {
            defaults.headers.Authorization = `Bearer ${token}`
            studioDefaults.headers.Authorization = `Bearer ${token}`
        }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Login>
))
