import React, { useState } from "react"
import { PlaceholderImageUrl } from "../../../../packages/editing/EditableImage"
import { EditableText } from "../../../../packages/editing/EditableText"
import { ImageToCSS, ImageToUrl } from "../../../../reactor/Types/File"
import { Stickers, Sticker } from "./Stickers"
import { Image } from "../../../../reactor/Types/File"
import { Localized } from "../../../../packages/localization/Localized"
import { Markdown } from "../../../../reactor/Types/Primitives/Markdown"
import { Section } from "../../../../packages/editing/Section"

Section(InfoBox)
function InfoBox(section: {
    stickers: Sticker[]

    frame: Image

    backgroundImage?: Image

    /**
     * @default 512
     */
    maxWidth?: number

    title: Localized<string>

    body: Localized<Markdown>

    justify: "top" | "center" | "bottom"

    callToAction: Localized<string>
    callToActionLocation: "inside" | "outside"
}) {
    const [ref, setRef] = useState<HTMLElement | null>(null)

    const w = section.maxWidth ?? 512

    return (
        <div
            ref={setRef}
            className="container flex-column flex-lg-row"
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%",
                backgroundImage: section.backgroundImage
                    ? ImageToCSS(section.backgroundImage)
                    : undefined,
            }}>
            <div
                ref={setRef}
                style={{
                    padding: 16,
                    position: "relative",
                    maxWidth: w,

                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                }}>
                <img
                    src={section.frame ? ImageToUrl(section.frame) : PlaceholderImageUrl()}
                    style={{
                        objectFit: "cover",
                        width: "100%",
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        color: "white",
                        paddingLeft: w / 5,
                        paddingRight: w / 5,
                        paddingTop: "calc(max(7%, 32px))",
                        paddingBottom: "calc(max(7%, 32px))",

                        display: "flex",
                        flexDirection: "column",
                        justifyContent:
                            section.justify === "top"
                                ? "flex-start"
                                : section.justify === "bottom"
                                ? "flex-end"
                                : "center",
                    }}>
                    {section.stickers && <Stickers stickers={section.stickers} />}
                    <h2>
                        <EditableText
                            obj={section}
                            prop="title"
                            style={{
                                color: "white",
                                textAlign: "center",
                                marginTop: 32,
                            }}
                        />
                    </h2>
                    <EditableText
                        obj={section}
                        prop="body"
                        isMarkdown={true}
                        isLocalized={true}
                        style={{
                            textAlign: "center",
                            paddingBottom: 32,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
