import React from "react"
import { useParams } from "react-router-dom"
import { AdyenComponent } from "../../../../../packages/adyen/web/AdyenComponent"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { usePartyContext } from "./PartyContext"

export function PaymentPage() {
    const { adyenOrderId } = useParams()
    const { section } = usePartyContext()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                padding: 32,
            }}>
            <div
                style={{
                    width: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <h1 style={{ marginBottom: 32 }}>
                    <EditableText
                        obj={section}
                        prop="payment"
                        isLocalized={true}
                        defaultText="Payment"
                    />
                </h1>
                {
                    <AdyenComponent
                        successUrl={`../confirmation/party-booking-order/${adyenOrderId}`}
                    />
                }
            </div>
        </div>
    )
}
