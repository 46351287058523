import { useCallback, useState } from "react"
import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { type Localized } from "../../../../packages/localization/Localized"
import { Uuid, type Markdown } from "../../../../reactor"
import { Collapsible } from "../views/Collapsible"
import { Chevron } from "../assets/Chevrons"
import { colors } from "../colors"
import { SectionWrapper } from "../views/SectionWrappper"

interface Accordion extends SectionWrapper {
    /**
     * The items to render as collapsible items in the accordion
     * @expand
     */
    items: {
        readonly id: Uuid<"AccordionCollapsible">
        /**
         * @title
         */
        title: Localized<string>
        text: Localized<Markdown>
    }[]

    /**
     * Use this option to limit open collapsible items to one
     * @default false
     */
    onlyOneOpen: boolean

    translations: {
        /**
         * Label for screen readers that will be prepended to the title of the collapsible item,
         * i.e. if the value is "Show" and the title of the item is "more information" it will be
         * read as "Show more information".
         * @default '{"en": "Show", "no":"Vis", "sv": "Visa", "dk": "Vis"}'
         */
        show: Localized<string>
    }
}

Section(Accordion)
function Accordion(section: Accordion) {
    const localize = useLocalize()
    const [openItems, setOpenItems] = useState<Uuid<"AccordionCollapsible">[]>([])
    const handleItemClick = useCallback(
        (action: "open" | "close", id: Uuid<"AccordionCollapsible">) => () => {
            if (action === "open") {
                if (section.onlyOneOpen) {
                    setOpenItems([id])
                } else {
                    setOpenItems([...openItems, id])
                }
            } else if (action === "close") {
                setOpenItems(openItems.filter((item) => item !== id))
            }
        },
        [openItems, setOpenItems]
    )

    return (
        <SectionWrapper section={section}>
            <div
                css={css({
                    width: 800,
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    textAlign: "left",
                })}>
                {section.items?.map((item) => {
                    const itemIsOpen = openItems.includes(item.id)

                    return (
                        <div
                            key={item.id.valueOf()}
                            css={css({
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                                marginBottom: 24,
                                ":last-of-type": { marginBottom: 0 },
                            })}>
                            <h4
                                role="button"
                                aria-label={`${localize(section.translations.show)} ${localize(
                                    item.title
                                )}`}
                                onClick={handleItemClick(itemIsOpen ? "close" : "open", item.id)}
                                css={css({
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: `1px solid ${colors.grey3}`,
                                    paddingBottom: 8,
                                })}>
                                {localize(item.title)}
                                <Chevron
                                    direction={itemIsOpen ? "up" : "down"}
                                    color="darkPurple"
                                    css={css({ flexShrink: 0, flexGrow: 0 })}
                                />
                            </h4>
                            <div>
                                <Collapsible key={item.id.valueOf()} collapsed={!itemIsOpen}>
                                    {localize(item.text)}
                                </Collapsible>
                            </div>
                        </div>
                    )
                })}
            </div>
        </SectionWrapper>
    )
}
