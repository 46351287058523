import { CSSProperties } from "react"

export const Animations = {
    funplays: (visible: boolean, index: number, totalCount: number): CSSProperties => ({
        // rotates the image into the screen as if coming in from below the viewer
        transform: visible
            ? "rotateX(0deg)"
            : index & 1
            ? "rotateY(20deg) rotateZ(10deg) translateZ(20px) scale(0.9)"
            : "rotateY(-20deg) rotateZ(-10deg) translateZ(20px) scale(0.9)",
        transformOrigin: "bottom center",
        transition: "all 800ms cubic-bezier(.16,.98,.49,1)",
        transitionDelay: "200ms",

        opacity: visible ? 1 : 0,
    }),
    presentOptions: (visible: boolean, index: number, totalCount: number): CSSProperties => ({
        // rotates the image into the screen as if coming in from below the viewer
        transform: visible
            ? "rotateX(0deg)"
            : index & 1
            ? "rotateY(60deg) rotateZ(90deg) translateZ(20px) scale(0.9)"
            : "rotateY(-60deg) rotateZ(-90deg) translateZ(20px) scale(0.9)",
        transformOrigin: "bottom center",
        transition: "all 800ms cubic-bezier(.09,1.34,.3,.95)",
        transitionDelay: `${500 + ((index + 1) * 400) / totalCount}ms`,

        opacity: visible ? 1 : 0,
    }),
}
export type Animation = keyof typeof Animations

export function SlideAnimation(
    currentIndex: number,
    index: number,
    totalCount: number
): CSSProperties {
    return {
        // rotates the image into the screen as if coming in from below the viewer
        transform:
            currentIndex === index
                ? "rotateX(0deg)"
                : currentIndex > index
                ? "rotateY(90deg) rotateZ(16deg) translateX(-50%) scale(0.9)"
                : "rotateY(-90deg) rotateZ(-19deg) translateX(50%) scale(0.9)",
        transformOrigin: "bottom center",
        transition: "all 450ms cubic-bezier(.21,.97,.38,1.02)",

        opacity: currentIndex === index ? 1 : 0,
    }
}
