import { css } from "@emotion/react"
import { Section } from "../../../../packages/editing/Section"
import { useComeBackTicketsByVenue } from "../client"
import { usePageContext } from "../Page"
import { Localized } from "../../../../packages/localization/Localized"
import { EditableText } from "../../../../packages/editing/EditableText"
import { File, FileToUrl, Image, ImageToUrl, Markdown } from "../../../../reactor"
import { responsiveCss } from "../css"

Section(ComeBackTicketPrices)
function ComeBackTicketPrices(section: {
    heading?: Localized<string>
    text?: Localized<Markdown>

    /**
     * Translation for "days" used for each price item
     *
     * @default '{"en": "days", "no": "dager", "sv": "dagar", "da": "dager"}'
     **/
    days: Localized<string>

    /**
     * Stickers to be displayed on the prices, in the same order as the prices
     */
    stickers?: Image[]

    /**
     * @defaultFile come-back-ticket-price-background.svg
     */
    priceBackgroundImage: File

    /**
     * @defaultFile come-back-ticket-price-background-small.svg
     */
    priceBackgroundImageSmall: File
}) {
    const page = usePageContext()
    const comeBackTicketsByVenue = useComeBackTicketsByVenue(page.venue?.id ?? null)
    if (!page.venue) return <></>

    return (
        <div
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 1200,
                    padding: "40px 18px",
                    margin: "0 auto",
                    width: "100%",
                },
                responsiveCss("min", "sm", {
                    padding: "80px 40px",
                    flexDirection: "column-reverse",
                }),
                responsiveCss("min", "xl", { padding: "80px 0" })
            )}>
            {(!!section.heading || !!section.text) && (
                <div
                    css={css(
                        {
                            display: "grid",
                            alignItems: "start",
                            gridTemplateColumns: "1fr",
                            gap: 32,
                        },
                        responsiveCss("min", "sm", {
                            gap: 50,
                            gridTemplateColumns: "1fr 1fr",
                            marginTop: 80,
                        })
                    )}>
                    <div>
                        <h2
                            css={css({
                                margin: 0,
                            })}>
                            <EditableText obj={section} prop="heading" isLocalized />
                        </h2>
                    </div>
                    <div>
                        <EditableText obj={section} prop="text" isLocalized isMarkdown />
                    </div>
                </div>
            )}
            <div
                css={css`
                    margin: 0 auto;
                    display: flex;
                    color: #fff;
                    max-width: 1200px;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    justify-content: space-around;

                    @media (min-width: 480px) {
                        flex-direction: row;
                    }
                `}>
                {comeBackTicketsByVenue.data?.map((t, index) => (
                    <div
                        key={t.id.toString()}
                        css={css(
                            {
                                position: "relative",
                                flex: 1,
                                marginRight: 12,
                                ":last-of-type": { marginRight: 0 },
                            },
                            responsiveCss("min", "sm", {
                                maxWidth: 320,
                                marginRight: 40,
                                ":last-of-type": { marginRight: 0 },
                            })
                        )}>
                        <img
                            src={FileToUrl(section.priceBackgroundImage)}
                            width="100%"
                            height="100%"
                            css={css`
                                @media (max-width: 479px) {
                                    display: none;
                                }
                            `}
                        />
                        <img
                            src={FileToUrl(section.priceBackgroundImageSmall)}
                            width="100%"
                            height="100%"
                            css={css`
                                @media (min-width: 480px) {
                                    display: none;
                                }
                            `}
                        />
                        {section.stickers?.[index] ? (
                            <img
                                src={ImageToUrl(section.stickers?.[index], {
                                    width: 100,
                                    format: "png",
                                })}
                                css={css`
                                    position: absolute;
                                    top: -12px;
                                    left: 38px;
                                    width: 20%;
                                    transform: rotate(-12deg);
                                    filter: drop-shadow(0 0 10px rgb(0 0 0 / 0.1));

                                    @media (min-width: 480px) {
                                        top: 18%;
                                        left: -1%;
                                        width: 23%;
                                    }
                                `}
                            />
                        ) : null}
                        <div
                            css={css`
                                position: absolute;
                                display: flex;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                align-items: center;
                                flex-direction: row-reverse;

                                @media (max-width: 479px) {
                                    padding: 10px 56px 0;
                                    justify-content: space-between;
                                }

                                @media (min-width: 480px) {
                                    padding: 10px 0 0;
                                    flex-direction: column;
                                    justify-content: center;
                                }
                            `}>
                            <div
                                css={css`
                                    font-family: "GT-Haptik-Black", sans-serif;
                                    font-size: 48px;
                                    display: flex;

                                    @media (min-width: 480px) {
                                        margin-bottom: min(16px, 1vw);
                                        font-size: min(64px, 5.5vw, 5cqw);
                                        line-height: 1;
                                    }
                                `}>
                                <div
                                    css={css`
                                        margin-right: 8px;
                                    `}>
                                    +
                                </div>
                                <div>{t.price.valueOf()}</div>
                            </div>
                            <div
                                css={css`
                                    text-decoration: underline;
                                    font-size: 18px;

                                    @media (min-width: 480px) {
                                        font-size: min(24px, 2.5vw, 2cqw);
                                    }
                                `}>
                                {t.numberOfDays.valueOf()}{" "}
                                <EditableText
                                    obj={section}
                                    prop="days"
                                    isLocalized
                                    style={{ display: "inline" }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
