import React from "react"
import { useNavigate } from "react-router-dom"
import { EditableText } from "../../../../../packages/editing/EditableText"
import { Email, PhoneNumber } from "../../../../../reactor/Types/Primitives"
import { CallToAction } from "../TextAndCallToAction"
import { DateBox } from "./DatePicker"
import { Field, TextField } from "./Field"
import { usePartyContext } from "./PartyContext"

export function ContactInfoPage() {
    const {
        section,
        nameOfTheBirthdayChild,
        setNameOfTheBirthdayChild,
        birthDate,
        setBirthDate,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        notes,
        setNotes,
    } = usePartyContext()
    const navigate = useNavigate()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowX: "clip",
                marginBottom: 64,
            }}>
            <div
                style={{
                    width: "100%",
                    maxWidth: 512,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 16,
                    marginTop: 16,
                    marginBottom: 32,
                }}>
                <h1 style={{ textAlign: "center", width: "100%", marginBottom: 32, marginTop: 16 }}>
                    <EditableText
                        obj={section}
                        prop="whoAreWeCelebrating"
                        defaultText="Who are we celebrating?"
                        isLocalized={true}
                    />
                </h1>
                <TextField
                    section={section}
                    prop="nameOfTheBirthdayChild"
                    value={nameOfTheBirthdayChild}
                    valueChanged={setNameOfTheBirthdayChild}
                />

                <Field section={section} prop="birthDate">
                    <DateBox date={birthDate} dateChanged={setBirthDate} />
                </Field>

                <h1 style={{ textAlign: "center", width: "100%", marginBottom: 32, marginTop: 48 }}>
                    <EditableText
                        obj={section}
                        prop="yourInformation"
                        defaultText="Your information"
                        isLocalized={true}
                    />
                </h1>
                <TextField
                    section={section.informationFields}
                    prop="firstName"
                    value={firstName}
                    valueChanged={setFirstName}
                />
                <TextField
                    section={section.informationFields}
                    prop="lastName"
                    value={lastName}
                    valueChanged={setLastName}
                />
                <TextField
                    section={section.informationFields}
                    prop="email"
                    value={email}
                    valueChanged={setEmail}
                    validator={Email}
                />
                <TextField
                    section={section.informationFields}
                    prop="phoneNumber"
                    value={phoneNumber}
                    valueChanged={setPhoneNumber}
                    validator={PhoneNumber}
                />
                <Field section={section.informationFields} prop="notes">
                    <textarea
                        style={{
                            marginTop: 8,
                            marginBottom: 8,
                            border: "none",
                            borderRadius: 8,
                            height: 120,
                            padding: 16,
                            width: "100%",
                        }}
                        id="name"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    />
                </Field>
            </div>
            <CallToAction
                disabled={!firstName || !lastName || !email || !phoneNumber}
                section={section}
                prop="contactInfoCallToAction"
                onClick={() => navigate("../summary")}
            />
        </div>
    )
}
