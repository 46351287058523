import React from "react"

export function Check() {
    return (
        <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.707 0.29347C14.0977 0.684137 14.0977 1.31546 13.707 1.70612L5.70657 9.707C5.31592 10.0977 4.68464 10.0977 4.29399 9.707L0.292891 5.70656C-0.0976304 5.31589 -0.0976304 4.68457 0.292891 4.29391C0.683476 3.90324 1.31664 3.90324 1.70728 4.29391L4.97215 7.58489L12.2944 0.29347C12.6851 -0.0978232 13.3164 -0.0978232 13.707 0.29347Z"
                fill="white"
            />
        </svg>
    )
}
